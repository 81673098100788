.location-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .location-select-container {
    padding: 2px 6px 2px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    background: rgba(196, 196, 196, 0.2);

    .ant-select {
      .ant-select-selector {
        outline: none;
        background: transparent;
        border: 0 !important;
        box-shadow: none !important;

        .ant-select-selection-item {
          color: #626687;
          font-size: 14px;

          font-style: normal;
          font-weight: 700;
          align-self: center;
        }
      }
    }
  }
}
