.landing {
  .content-container {
    // background-image: url('../../assets/icons/landing/bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    object-fit: cover;
    width: 100%;
    min-height: 80vh;
    padding: 45px;

    .content-card {
      // overflow: hidden;
      min-width: 320px;
      max-width: 350px;
      //  display: flex;
      padding: 24px 14px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      border-radius: 16px;
      border: 1px solid #fff;
      background: rgba(234, 244, 238, 0.6);
      backdrop-filter: blur(15px);

      .tabs-container {
        padding: 0 10px;

        .ant-tabs {
          .ant-tabs-nav {
            &::before {
              border: 0 !important;
            }
          }

          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: #04c23a !important;
              font-size: 12px;

              font-weight: 700;
              line-height: 16.5px;
              margin: 0;
            }
          }

          .ant-tabs-ink-bar {
            background: #04c23a !important;
          }
        }
      }
    }

    .menu-icon {
      display: none;
      cursor: pointer;
      position: absolute;
      top: 50px;
      right: 50px;
    }

    position: relative;

    .vr-toor-iframe {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // z-index: -1;
      object-fit: cover;
    }
  }

  @media only screen and (max-width: 485px) {
    .content-container {
      .content-card {
        display: none;
      }

      .menu-icon {
        display: block;
        float: right;
      }
    }
  }
}

.sale-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .sale-price-container {
    .sale-price-title {
      color: #626687;
      font-size: 12px;

      font-weight: 700;
      line-height: 16.5px;
      margin-bottom: 6px;
    }

    .sale-price-values-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .MuiSlider-rail {
      height: 9px;
    }

    .MuiSlider-track {
      color: #1b1c57 !important;
      height: 9px;
    }

    .MuiSlider-thumb {
      color: #1b1c57 !important;
      width: 28px;
      height: 28px;
    }

    .slider-values-container {
      span {
        margin-top: 5px;
        color: var(--light-dark, #626687);
        text-align: right;
        font-family: Cairo;
        font-size: 13.654px;
        font-style: normal;
        font-weight: 400;
        line-height: 27.308px;
      }
    }

    .sale-price-title {
      margin-bottom: 10px;
    }
  }

  .search-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .search-btn {
      color: #fff;
      text-align: right;
      font-size: 12px;

      font-weight: 700;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      cursor: pointer;
      border: none;
      color: #ffffff;
      background: #04c23a;
      border-radius: 9px;
      padding: 8px 16px;
      width: 86px;
    }
  }

  .sale-select-container {
    padding: 2px 6px 2px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    border: 0.75px solid #1b1c57;
    background: #eaf4ee;

    .ant-select {
      .ant-select-selector {
        outline: none;
        background: transparent;
        border: 0 !important;
        box-shadow: none !important;
      }
    }
  }

  .sale-date-container {
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    background: #eaf4ee;
    border: 0.75px solid #1b1c57;

    .ant-picker {
      outline: none;
      background: transparent;
      border: 0 !important;
      box-shadow: none !important;
      width: 100%;
    }
  }

  @media only screen and (max-width: 485px) {
    .sale-select-container {
      background-color: white;
    }
  }
}

.rent-container {
  .ant-radio-wrapper {
    span {
      color: #626687;
      font-size: 12px;
      font-weight: 700;
      line-height: 16.5px;
    }
  }

  .ant-radio-wrapper {
    .ant-radio {
      display: none;
    }

    .checkbox-content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;

      .checkbox-checked {
        display: none;
      }

      .checkbox-not-checked {
        display: flex;
      }
    }
  }

  .ant-radio-wrapper-checked {
    .checkbox-content {
      .checkbox-checked {
        display: flex;
      }

      .checkbox-not-checked {
        display: none;
      }
    }
  }
}
