.auction-start-modals {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auction-start-modals h4 {
  color: #04c23a;
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 1.62rem;
}
.auction-start-modals p {
  color: #1b1c57;
  text-align: right;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: .5rem;
}

.auction-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.875rem;
  margin-top: 1.32rem;
  width: 100%;
}

.auction-buttons .go-back {
  border: 1.5px solid #04C23A !important;
  width: 30%;
  color: #04C23A;
}

.auction-buttons .go-back:hover .span {
  color: #04C23A;
}

:where(.css-dev-only-do-not-override-nnuwmp).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  order: 1.5px solid #04C23A !important;
  color: #04C23A;
}

.auction-buttons .go-to-site{ 
  width: 70%;
  
}