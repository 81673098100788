.login_page {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 70px 16px;
  background: #f5f5f5;
  width: 100%;
  .content {
    max-width: 410px;
    width: 100%;
    form {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;
      margin-top: 32px;
    }
    .inputs_login {
      width: 100%;
      .s_input {
        width: 100%;
        height: 46px;
      }
    }
    .login_action {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        cursor: pointer;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #1b1c57;
      }
      .forgot_pwd {
        color: #1d4ed8;
      }
    }
    .ldap {
      width: 100%;
      margin-top: 16px;
      .divider_text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 10px;
        margin-bottom: 16px;

        .divider {
          // width: 100%;
          height: 1px;
          background-color: #1b1c57;
          width: 100%;
        }
        .text {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          font-size: 14px;
          line-height: 26px;
          text-align: right;
          text-transform: capitalize;
          white-space: nowrap;

          color: #1b1c57;
        }
      }
      .items {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        img {
          cursor: pointer;
        }
      }
    }
    .account {
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      text-transform: capitalize;
      color: #000000;

      span {
        color: #1d4ed8;
        cursor: pointer;
      }
    }
  }
}
