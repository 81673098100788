.defining-price {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .title {
    color: #04c23a;
    text-align: center;
    font-size: 18px;

    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  p {
    color: #1b1c57;
    text-align: center;
    font-size: 15px;

    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .label {
    text-align: left;
  }

  .ant-switch {
    min-width: 37.5px;
  }

  .ant-switch-checked {
    background-color: #04c23a;
  }

  .content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .input-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      width: 112.5px;
      padding: 4px 8px;
      border-radius: 12px;
      border: 0.75px solid var(--dark-blue, #1b1c57);

      input {
        border: 0;
        box-shadow: none;
        outline: none;
        width: 100%;
      }
    }
  }

  .payment_method {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    flex-wrap: wrap;

    .payment_method-items {
      color: #fff;
      font-size: 12px;

      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      border-radius: 9px;
      background: #04c23a;
      padding: 2px 8px;
      cursor: pointer;
    }
  }

  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;

    .btn {
      width: 100%;
      border: 0;
      border-radius: 9px;
      border: 1px solid #04c23a;
      background-color: white;
      color: #04c23a;
      font-size: 15px;

      font-style: normal;
      font-weight: 700;
      line-height: 16.5px;
      padding: 12px 18px;
      cursor: pointer;
      white-space: nowrap;
    }

    .btn-solid {
      background: #04c23a;
      color: #fff;
    }
  }
}
