.ep-arrow {
  display: none;
}
.date-range {
  display: flex;
  align-items: center;
  gap: 7px;
  justify-content: center;
  .nb-days {
    font-size: 12px;
  }
}
