.property_modal {
  //   width: 360px;
  overflow: hidden;

  .ant-modal {
    border-radius: 12px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
  }
  .ant-modal-content {
    padding: 0px !important;
    border-radius: 12px !important;
    width: 400px !important;
    position: relative;
  }
  .close_icon {
    position: absolute;
    z-index: 10;
    top: -20px;
    right: 4px;
  }
  .list-sale-card {
    max-width: 400px;
    .picture-container {
      width: 100%;
      .picture {
        width: 100%;
      }
    }
  }
}
