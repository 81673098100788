.empty_broker {
  min-height: calc(100vh - 400px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.brokers-page {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  padding: 24px 0;

  .brokers-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title {
      color: #1b1c57;
      text-align: center;
      font-size: 24px;

      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }

    .brokers-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 24px;

      .brokers-col1 {
        display: flex;
        padding: 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        border-radius: 9px;
        background: #fff;
        width: 295px;

        .broksers-label {
          color: #1b1c57;
          font-size: 15px;

          font-style: normal;
          font-weight: 500;
          line-height: 18px;
        }
      }

      .brokers-col2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .brokers-input-container {
          border-radius: 9px;
          background: #fff;
          display: flex;
          padding: 9px 18px;
          justify-content: space-between;
          align-items: center;
          gap: 12px;
          width: 100%;
          max-width: 325px;

          input {
            border: 0;
            width: 100%;
            outline: none !important;
          }

          .register-btn {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            text-transform: capitalize;
            color: #1b1c57;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: none;
            color: #ffffff;
            background: #04c23a;
            border-radius: 12px;
            padding: 8px 16px;
          }
        }

        .btn-container {
          align-self: center;

          .btn {
            border: 0;
            border-radius: 9px;
            background: #04c23a;
            color: #fff;
            font-size: 12px;

            font-style: normal;
            font-weight: 700;
            line-height: 16.5px;
            padding: 12px 18px;
            cursor: pointer;
          }
        }
      }

      @media only screen and (max-width: 1080px) {
        display: grid;
        justify-content: center;

        .brokers-col1 {
          width: 100%;
        }

        .brokers-col2 {
          .brokers-input-container {
            max-width: none;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 701px) {
  .broker-mobile-header {
    display: none !important;
  }
}

@media only screen and (max-width: 700px) {
  .brokers-page {
    .brokers-container {
      .brokers-row {
        .brokers-col1 {
          display: none;
        }

        .brokers-col2 {
          .brokers-input-container {
            display: none;
          }
        }
      }
    }
  }
}

.broker-mobile-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 90%;
  gap: 10px;

  button {
    border: 0;
    background: transparent;
    outline: none;
    cursor: pointer;
  }

  &>.map-search {
    display: flex;
    padding: 4px;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    background: var(--white, #fff);

    :focus,
    :active {
      background: var(--light-grey, rgba(196, 196, 196, 0.5));
    }
  }

  &>.menu {
    display: flex;
    padding: 4px;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    background: var(--white, #fff);
  }

  &>.search-container {
    display: flex;
    width: 150px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 6px;
    background: #fff;
    flex-grow: 1;

    &>input {
      color: var(--light-dark, #626687);
      font-family: Cairo;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      /* 150% */
      border: none;
      outline: none;
      padding: 0px 8px;
    }

    &>.search-btn {
      display: flex;
      padding: 4px 8px;
      align-items: center;
      gap: 4px;
      border-radius: 6px 6px 6px 6px;
      background: var(--basic, #04c23a);
      cursor: pointer;
      border: none;
      outline: none;
    }
  }

  &>.filter-btn {
    display: flex;
    padding: 0px 8px;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 6px;
    background: var(--white, #fff);

    &>span {
      color: var(--dark-blue, #1b1c57);
      text-align: right;
      cursor: pointer;
      font-family: Cairo;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
    }
  }

  &>.recent-btn {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    background: var(--white, #fff);

    &>span {
      color: var(--dark-blue, #1b1c57);
      text-align: right;
      font-family: Cairo;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.transparent-modal {
  top: 35% !important;
  transform: translateY(-50%);
  margin: 0 auto;
  left: 0;
  right: 0;

  .ant-modal-body {}
}

.transparent-modal .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}

.transparent-modal .ant-modal-header,
.transparent-modal .ant-modal-body,
.transparent-modal .ant-modal-footer {
  background-color: transparent;
}

.transparent-modal .ant-modal-close-x {
  display: flex;
  padding: 8px 15px !important;
  align-items: flex-start;
  border-radius: 12px;
  background: rgba(196, 196, 196, 0.2);
  color: white;
  transform: translateY(-100%);
}

.brokers-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  width: 100%;
  background: rgba(196, 196, 196, 0.2);
  padding: 0 12px;
  margin: 0;
  gap: 0;
  min-width: 100%;

  .ant-select {
    .ant-select-selector {
      outline: none;
      background: transparent;
      border: 0 !important;
      box-shadow: none !important;
      color: #626687;
      font-size: 10.5px;
      font-style: normal;
      font-weight: 700;
      line-height: 16.5px;
    }
  }
}

.broker-desktop-header {
  display: flex;
  gap: 6px;
  justify-content: start;
  align-items: center;

  button {
    border: 0;
    background: transparent;
    outline: none;
    cursor: pointer;
  }

  &>.map-search {
    display: flex;
    padding: 6px;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    background: var(--white, #fff);

    &>svg {
      width: 18px;
      height: 18px;
    }

    :focus,
    :active {
      background: var(--light-grey, rgba(196, 196, 196, 0.5));
    }
  }

  &>.menu {
    display: flex;
    padding: 6px;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    background: var(--light-grey, rgba(196, 196, 196, 0.5));

    &>svg {
      width: 18px;
      height: 18px;
    }
  }
}

.no-map-brokers-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  width: 75%;

  .brokers-cards {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-inline: auto;
    gap: 24px;

    &>.brokers-card {
      display: flex;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      gap: 24px;
      border-radius: 12px;
      background: #fff;
    }
  }
}

@media screen and (max-width: 700px) and (min-width: 500px) {
  .no-map-brokers-cards {
    margin-inline: auto;
    padding-inline: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .no-map-brokers-cards {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline: auto;
  }

  .broker-desktop-header {
    display: none;
  }
}

.map-brokers-cards {
  display: flex;
  gap: 24px;
  // margin-inline: auto;

  &>.brokers-map {
    width: 60%;
  }

  .brokers-cards {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    transform: translateY(-18%);
    max-height: calc(570px + 20vh);
    overflow-y: auto;

    @media screen and (min-width: 1200px) and (max-height: 650px) {
      max-height: calc(570px + 25vh);
    }

    &>.brokers-card {
      display: flex;
      padding: 12px 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 12px;
      background: #fff;
    }
  }
}