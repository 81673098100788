.remove-favorite-modal {
  display: inline-flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 26px;
  border-radius: 8px;
  background: #fff;
  width: 100%;

  & > h3 {
    color: var(--red, #ff1d1d);
    text-align: center;
    font-family: Cairo;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  & > p {
    color: #1b1c57;
    text-align: center;
    font-family: Cairo;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & > .buttons {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: center;

    & > button {
      border: 0;
      outline: none;
      cursor: pointer;
    }

    .remove {
      display: flex;
      width: 100px;
      padding: 12px 8px;
      justify-content: center;
      align-items: center;
      gap: 24px;
      border-radius: 9px;
      background: var(--red, #ff1d1d);
      color: #fff;
      font-family: Cairo;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 16.5px; /* 110% */
    }

    .cancel {
      display: flex;
      width: 100px;
      padding: 12px 8px;
      justify-content: center;
      align-items: center;
      gap: 24px;
      border-radius: 9px;
      border: 1px solid var(--red, #ff1d1d);
      color: var(--red, #ff1d1d);
      font-family: Cairo;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 16.5px; /* 110% */
      background-color: white;
    }
  }
}
