.specifications {
  .tag-slider {
    display: flex;
    align-items: center;
    // overflow: hidden;
    // max-width: 100%;
    gap: 6px;
    margin-top: 6px;
    margin-bottom: 10px;
    .arrow {
      font-size: 24px;
      cursor: pointer;
      user-select: none;

      display: flex;
      align-items: center;
      justify-content: center;

      display: flex;
      padding: 3px;
      justify-content: center;
      align-items: center;
      gap: 3px;

      border-radius: 9px;
      background: rgba(196, 196, 196, 0.2);
    }

    .tags {
      display: flex;
      gap: 10px;
      flex-wrap: nowrap;
      overflow-x: auto;
      scroll-behavior: smooth;
      // width: 40%;
      position: relative;
      max-width: 500px;

      scrollbar-width: none; // Hide the scrollbar on Firefox
      -ms-overflow-style: none; // Hide the scrollbar on IE and Edge
      &::-webkit-scrollbar {
        display: none; // Hide the scrollbar on WebKit
      }
      @media only screen and (max-width: 1466px) {
        max-width: 420px;
      }
      @media only screen and (max-width: 1366px) {
        max-width: 400px;
      }

      @media only screen and (max-width: 950px) {
        max-width: 300px;
      }

      // @media only screen and (max-width: 820px) {
      //   max-width: 400px;
      // }
      @media only screen and (max-width: 520px) {
        max-width: 300px;
      }
      @media only screen and (max-width: 430px) {
        max-width: 270px;
      }
      @media only screen and (max-width: 400px) {
        max-width: 250px;
      }
      .tag {
        width: fit-content !important;
        flex-shrink: 0; // This prevents tags from shrinking to fit available space
        width: fit-content !important;
      }
    }
  }
}
