.profile-org {
  padding: 70px 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  width: 100%;

  .upload-file {
    display: none;
  }

  .edit-mobile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 100%;

    .icon {
      cursor: pointer;
      display: none;
    }
  }

  .title {
    color: #1b1c57;
    text-align: center;
    font-size: 24px;

    font-weight: 700;
    text-transform: capitalize;
  }

  .content-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 60px;
    width: 90%;

    .label {
      color: #1b1c57;
      font-size: 18px;

      font-weight: 600;
      text-transform: capitalize;
    }

    .row-1 {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 24px;

      img {
        max-width: 350px;
        max-height: 350px;
      }

      .btn {
        border: 0;
        border-radius: 9px;
        background: #04c23a;
        padding: 8px 24px;
        color: #fff;
        font-size: 16px;

        font-weight: 700;
        line-height: 16.5px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }
    }

    .row-2 {
      display: flex;
      flex: 2;
      flex-direction: column;
      gap: 24px;

      .inputs_contact-info {
        display: flex;
        flex-direction: column;
        gap: 18px;
      }
    }

    .row-3 {
      display: flex;
      flex: 2;
      flex-direction: column;
      gap: 24px;

      .site-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        .btn {
          border: 0;
          border-radius: 9px;
          background: #04c23a;
          padding: 8px 24px;
          color: #fff;
          font-size: 16px;

          font-weight: 700;
          line-height: 16.5px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }
      }

      .sale-component {
        .sale-select-container {
          background-color: #fff;
        }
      }

      .map {
        border: 0;
        border-radius: 6px;
      }
    }
  }

  .btn-container {
    text-align: center;

    .btn {
      border: 0;
      border-radius: 9px;
      background: #04c23a;
      padding: 18px 24px;
      color: #fff;
      font-size: 18px;

      font-weight: 700;
      line-height: 16.5px;
      cursor: pointer;
    }
  }

  .company-link-mobile {
    display: none;
  }
  .company-link-desktop {
    display: unset;
  }

  @media only screen and (max-width: 1440px) {
    .content-container {
      flex-wrap: wrap;

      .row-3 {
        flex-basis: 100%;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .edit-mobile-container {
      justify-content: space-between;

      .icon {
        display: block;
      }
    }

    .content-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 95%;
      flex-wrap: unset;
      gap: 12px;

      .row-1 {
        gap: 8px;
        img {
          width: 100%;
        }

        .btn-container {
          align-self: center;
        }
      }

      .row-2,
      .row-3 {
        gap: 8px;
        width: 100%;
        flex-basis: auto;
      }
    }

    .company-logo-title {
      text-align: center;
    }

    .company-logo-image {
      max-width: 70% !important;
      margin-inline: auto;
    }

    .company-link-mobile {
      display: unset;
    }
    .company-link-desktop {
      display: none;
    }
    
    .no-responsive {
      display: none;
    }
  }
}
