.menu {
  display: flex;
  align-items: center;

  .avatar {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .profile__avatar {
    cursor: pointer !important;
  }
}
.menu_main {
  .logout_item {
    color: #d32f2f !important;
  }
  .MuiPaper-root.MuiPaper-elevation {
    display: flex;
    width: 180px;
    padding: 0 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    box-shadow: none;
    margin-top: 6px;
    ul {
      width: 100%;
      li {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
        text-transform: capitalize;
        border-bottom: 1px solid #c0c0c0;
        padding: 0 !important;
        margin: 0 !important;
        margin-bottom: 10px !important;

        display: flex;
        align-items: center;
        gap: 15px;
      }
    }
  }
  .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters {
    background-color: white !important;
    // &:hover {
    //   background-color: red !important;
    // }
  }
}
