.remove-fav-container {
    display: inline-flex;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 26px;
    border-radius: 8px;
    flex-wrap: wrap;
width: 100%;
    .title {
        color: var(--red, #FF1D1D);
        text-align: center;
        font-family: Cairo;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

    }

    .description {
        color: #1B1C57;
        text-align: center;
        font-family: Cairo;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .modal-buttons-container {
        display: flex;
        gap: 14px;
        flex-wrap: wrap;
        justify-content: center;
        .delete-button {
            cursor: pointer;
            display: flex;
            width: 150px;
            padding: 12px;
            justify-content: center;
            align-items: center;
            gap: 24px;
            border-radius: 9px;
            background: var(--red, #FF1D1D);
            color: #FFF;
            font-family: Cairo;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 16.5px;
            border: 0px;
        }

        .cancel-button {
            cursor: pointer;
            display: flex;
            width: 150px;
            padding: 12px;
            justify-content: center;
            align-items: center;
            gap: 24px;
            border-radius: 9px;
            border: 1px solid var(--red, #FF1D1D);
            color: var(--red, #FF1D1D);
            font-family: Cairo;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 16.5px;
        }
    }

}