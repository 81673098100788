.tabs-container {
  margin-top: -8px;
  margin-bottom: 6px;
  

  .ant-tabs-nav {
    &::before {
      border: 0 !important;
    }
  }

  .ant-tabs-tab-btn {
    color: #c0c0c0;
    font-size: 10px;

    font-style: normal;
    font-weight: 700;
    line-height: 16.5px;

    @media only screen and (max-width: 450px) {
      font-size: 8px;
      font-style: normal;
      font-weight: 600;
      line-height: 14.5px;
    }
  }

  .ant-tabs-tab {
    padding: 5px 0 !important;
    // margin: 0 10px 0 0 !important;

    @media only screen and (max-width: 1220px) {
      margin: 0 10px !important;
    }
    @media only screen and (max-width: 920px) {
      margin: 0 6px !important;
    }

    // &:first-child {
    //   margin: 0;
    // }
  }
  //disabled text
  .ant-tabs-tab-disabled {
    display: none;
    .ant-tabs-tab-btn {
      text-decoration: line-through;
    }
  }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #1b1c57 !important;
      font-size: 10.5px;
      font-style: normal;
      font-weight: 700;
      line-height: 16.5px;
      @media only screen and (max-width: 550px) {
        font-size: 8px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.5px;
      }
    }
  }

  .ant-tabs-ink-bar {
    background: #1b1c57 !important;
  }

  .ant-tabs-nav {
    margin: 0 !important;
  }

  .ant-tabs-nav-list {
    margin-bottom: 2px;
    @media only screen and (max-width: 520px) {
      // display: none;
      gap: 0 !important;
    }
  }

  .ant-tabs-nav-list {
    gap: 0px;
    margin-bottom: 7px;
  }
  @media only screen and (max-width: 420px) {
    // display: none;
  }
}
.daily_case {
  // margin-top: -18px !important;
}
// .tabs-container {
//   margin-top: 4px;

//   .ant-tabs-nav {
//     &::before {
//       border: 0 !important;
//     }
//   }

//   .ant-tabs-tab-btn {
//     color: #c0c0c0;
//     font-size: 10.5px;

//     font-style: normal;
//     font-weight: 700;
//     line-height: 16.5px;
//   }

//   .ant-tabs-tab {
//     padding: 5px 0 !important;
//     margin: 0;

//     &:first-child {
//       margin: 0;
//     }
//   }

//   .ant-tabs-tab-active {
//     .ant-tabs-tab-btn {
//       color: #1b1c57;
//       font-size: 10px;

//       font-style: normal;
//       font-weight: 700;
//       line-height: 16.5px;
//     }
//   }

//   .ant-tabs-ink-bar {
//     background: #1b1c57 !important;
//   }

//   .ant-tabs-nav {
//     margin: 0 !important;
//   }

//   .ant-tabs-nav-list {
//     margin-bottom: 2px;
//   }

//   .ant-tabs-nav-list {
//     gap: 12px;
//   }

//   // @media only screen and (max-width: 420px) {
//   //   display: none;
//   // }
// }
