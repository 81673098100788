.contact-container {
  display: flex;
  // width: 100vw;
  height: auto;
  background: #fcfdfd;
}

.left-col {
  width: 45%;
  height: 100vh;
  background-image: url("../../assets/brand/pexels-binyamin-mellish-106399.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 1100px) {
    display: none;
  }
}

.logo {
  width: 10rem;
  padding: 1.5rem;
}

.right-col {
  background: #fcfdfd;
  width: 50%;
  padding: 1rem 3.5rem;
  @media (max-width: 1100px) {
    width: 100%;
    padding: 1rem 20px;
  }

  & > .contact-title {
    margin-bottom: 2rem;
    margin-top: 1rem;
    font-weight: 600;
  }
}

.contact-title,
.contact-input-label,
.contact-submit-button,
.description {
  font-family: "Cairo";
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #1b1c57;
}

.contact-title {
  color: #1b1c57;
  text-transform: uppercase;
  font-size: 2.5rem;
  letter-spacing: 0.5rem;
  font-weight: 300;
}

.contact-paragraph {
  color: #818386;
  font-size: 0.9rem;
  letter-spacing: 0.01rem;
  margin: 0.25rem 0;
}

.contact-input-label,
.description {
  color: #818386;
  text-transform: uppercase;
  font-size: 0.625rem;
}

.form-group {
  @media (max-width: 1100px) {
    width: 100%;

    & > input,
    textarea {
      width: 75vw;
    }
  }
}

form {
  width: 100%;
  position: relative;
  margin-top: 1rem;
  padding: 1rem 0;
}

.contact-input,
.contact-textarea,
.contact-input-label {
  width: 40vw;
  display: block;
  @media (max-width: 700px) {
    width: max-content;
  }
}

p,
placeholder,
.contact-input,
.contact-textarea {
  font-family: "Cairo";
}

input::placeholder,
.contact-textarea::placeholder {
  color: #010712;
}

.contact-input,
.contact-textarea {
  color: #010712;
  font-weight: 500;
  background: #fcfdfd;
  border: none;
  border-bottom: 1px solid #818386;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  outline: none;
}

.contact-textarea {
  resize: none;
}

.contact-submit-button {
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ffffff;
  height: 38px;
  background: #1b1c57;
  border-radius: 12px;
  padding: 8px 16px;
}

input:hover,
.contact-textarea:hover,
.contact-submit-button:hover {
  opacity: 0.5;
}

.contact-submit-button:active {
  opacity: 0.8;
}
.description {
  margin-left: 1.25rem;
}

input:checked + .slider {
  background-color: #3b3636;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

#error,
#success-msg {
  width: 40vw;
  margin: 0.125rem 0;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-family: "Cairo";
  color: #818386;
}

#success-msg {
  transition-delay: 3s;
}

@media only screen and (max-width: 950px) {
  .logo {
    width: 8rem;
  }
  .contact-title {
    font-size: 1.75rem;
  }
  p {
    // font-size: 0.7rem;
  }
  .contact-input,
  .contact-textarea,
  .contact-submit-button {
    font-size: 0.65rem;
  }
  .description {
    font-size: 0.3rem;
    margin-left: 0.4rem;
  }
  .contact-submit-button {
    width: 7rem;
  }
  .theme-switch-wrapper {
    width: 120px;
  }
  .theme-switch {
    height: 28px;
    width: 50px;
  }

  .theme-switch input {
    display: none;
  }

  .slider:before {
    background-color: #fff;
    bottom: 0.25rem;
    content: "";
    width: 20px;
    height: 20px;
    left: 0.25rem;
    position: absolute;
    transition: 0.4s;
  }
  input:checked + .slider:before {
    transform: translateX(16px);
  }
}

.is-invalid {
  border: 1px solid red !important;
  border-radius: 10px;
  padding-inline: 10px;
}

.invalid-feedback {
  color: red; // Change this to the desired error text color
  font-size: 0.75rem;
  margin-bottom: 0.25rem;
}
