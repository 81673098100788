.favorites-sale-page {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  padding: 24px 0;

  .favorites-sale-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .title {
      color: #1b1c57;
      text-align: center;
      font-size: 24px;

      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 24px;

      .col1 {
        display: flex;
        padding: 12px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        border-radius: 9px;
        background: #fff;
        width: 295px;

        .label {
          color: #1b1c57;
          font-size: 15px;

          font-style: normal;
          font-weight: 500;
          line-height: 18px;
        }

        .filter-container {
          padding: 2px 6px 2px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 9px;
          background: rgba(196, 196, 196, 0.2);
          width: 100%;

          .ant-select {
            .ant-select-selector {
              outline: none;
              background: transparent;
              border: 0 !important;
              box-shadow: none !important;
              color: #626687;
              font-size: 10.5px;

              font-style: normal;
              font-weight: 700;
              line-height: 16.5px;
            }
          }
        }
      }

      .col2 {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .input-container {
          border-radius: 9px;
          background: #fff;
          display: flex;
          padding: 9px 18px;
          justify-content: space-between;
          align-items: center;
          gap: 12px;
          width: 100%;
          max-width: 325px;

          input {
            border: 0;
            width: 100%;
            outline: none !important;
          }

          .register-btn {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            text-transform: capitalize;
            color: #1b1c57;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: none;
            color: #ffffff;
            background: #04c23a;
            border-radius: 12px;
            padding: 8px 16px;
          }
        }

        .btn-container {
          align-self: center;

          .btn {
            border: 0;
            border-radius: 9px;
            background: #04c23a;
            color: #fff;
            font-size: 12px;

            font-style: normal;
            font-weight: 700;
            line-height: 16.5px;
            padding: 12px 18px;
            cursor: pointer;
          }
        }
      }

      @media only screen and (max-width: 1080px) {
        display: grid;
        justify-content: center;

        .col1 {
          width: 100%;
        }

        .col2 {
          .input-container {
            max-width: none;
          }
        }
      }
    }
  }
}

.fav-title {
  color: #1b1c57;
  text-align: center;
  font-family: Cairo;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin: 24px 0;
}


@media only screen and (max-width: 475px) {
  .filter-container {
    width: 100% !important;
  }
}