.ant-modal .ant-modal-content {
    max-width: 342px;
}

.defining-price {
    max-width: 342px;
    .label2:lang(ar) {
        text-align: right;
    }
    .label2:lang(en) {
        text-align: left;
    }
}

.payment-method {
    display: flex;
    justify-content: center;
    gap: 8px;

    label {
        background-color: #04C23A;
        padding: 4px 12px;
        color: var(--white, #FFF);
        font-family: Cairo;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        border-radius: 9px;
        cursor: pointer;

        input {
            display: none;
        }

        &.selected {
            border: 1px solid #04C23A;
            background-color: white;
            color: #04C23A;
            font-family: Cairo;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
        }
    }
}