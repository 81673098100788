.select_component {
  position: relative;
  width: 100% !important;
  & > div {
    width: 100%;
    max-width: 100%;
  }

  .ant-select-show-search {
    //     z-index: 10;

    width: 100%;
    & > div {
      width: 100%;
    }
  }
  .gray_select {
    .ant-select-selector {
      background-color: #c4c4c4cc;
      .ant-select-selection-placeholder {
        margin-left: 30px;
        // display: flex;
        // align-items: center;
        // margin-top: -1px;
        font-family: "Cairo";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #626687;
        margin-right: 30px !important;
      }
    }
  }
  .ant-select-selector {
    padding: 7px !important;
    background: #ffffff;
    border: 0.75px solid rgba(196, 196, 196, 0.5) !important;
    border-radius: 9px;
    height: none;
    .ant-select-selection-search {
      display: flex;
      justify-content: center;
      align-items: center;
      // padding-left: 30px !important;

      input {
        height: 100%;
        padding-left: 25px !important;
        padding-right: 25px !important;
      }
    }
  }
  .ant-select-selection-placeholder {
    margin-left: 34px;
    // display: flex;
    // align-items: center;
    // margin-top: -1px;
    font-family: "Cairo";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #626687;
    margin-right: 34px !important;
  }
  .ant-select-selection-overflow {
    padding-left: 30px;
  }
  .icon_prefix {
    position: absolute;
    left: 9px;
    z-index: 5;
    top: 4px;
  }

  .icon_prefix_ar {
    position: absolute;
    right: 9px;
    z-index: 5;
    top: 4px;
  }

  .select_item {
    .ant-select-selector {
      height: 46px !important;
    }
    .ant-select-selection-item {
      margin-right: 35px;
      padding-left: 35px !important;
    }
    // .ant-select-selection-placeholder {
    //   margin-left: 30px !important;
    // }
  }
}

.without_prefix {
  .ant-select-selection-placeholder {
    margin-left: 0px !important;
    display: flex;
    align-items: center;
  }
  .ant-select-selection-placeholder {
    margin-left: 0px;
  }

  .select_item {
    .ant-select-selector {
      height: 46px !important;
    }
    .ant-select-selection-item {
      padding-left: 35px !important;
      margin-right: 35px;
    }
    .ant-select-selection-placeholder {
      margin-left: 7px !important;
      margin-right: 7px;
    }
  }
}
