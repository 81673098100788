.profile-user-form {
  width: 100%;

  .profile-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    padding: 60px 12px;
    @media only screen and (max-width: 768px) {
      gap: 15px;
    }
    .edit-mobile-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      width: 100%;

      .icon {
        cursor: pointer;
        display: none;
      }
    }

    .title {
      color: #1b1c57;
      text-align: center;
      font-size: 24px;
      font-weight: 700;
      text-transform: capitalize;
    }

    .profile-container {
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 60px;
      @media only screen and (max-width: 1380px) {
        width: 80%;
      }
      @media only screen and (max-width: 768px) {
        gap: 12px;
      }

      .label {
        color: #1b1c57;
        font-size: 18px;
        font-weight: 600;
        text-transform: capitalize;
      }

      .row {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 18px;
        @media only screen and (max-width: 1380px) {
          gap: 12px;
        }
        .sale-component {
          .sale-select-container {
            background-color: #fff;
            height: 42px;
          }
        }
      }

      @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
        width: 100%;
      }
    }.profile-container:lang(ar) {
      flex-direction: row-reverse;
    }

    .btn-container {
      text-align: center;

      .btn {
        border: 0;
        border-radius: 9px;
        background: #04c23a;
        padding: 18px 24px;
        color: #fff;
        font-size: 18px;

        font-weight: 700;
        line-height: 16.5px;
        cursor: pointer;
      }

      .profile-submit-btn-hidden {
        display: none;
      }
    }

    @media only screen and (max-width: 768px) {
      .edit-mobile-container {
        width: 100%;
        justify-content: space-between;

        .icon {
          display: block;
        }
      }

      .no-responsive {
        display: none;
      }
    }
  }
}
