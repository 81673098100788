.slider-input {
  margin-top: -8px;

  .slider-values-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -15px;

    span {
      gap:3px;
      color: #626687;
      font-size: 9px;
      display: flex;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .MuiSlider-rail {
    height: 4px;
  }

  .MuiSlider-track {
    color: #1b1c57 !important;
    height: 4px;
  }

  .MuiSlider-thumb {
    color: #1b1c57 !important;
    width: 12px;
    height: 12px;
  }
}