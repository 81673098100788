.management-sale-org {
  padding: 24px 0;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  // min-height: calc(100vh - 300px);
  padding-bottom: 62px;
  .not_data {
    height: 300px;
  }

  .container-management-sale-org {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media only screen and (max-width: 720px) {
      width: 95%;
    }

    .title {
      color: #1b1c57;
      text-align: center;
      font-size: 24px;

      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }

    .tabs {
      align-self: center;

      .tabs-btns-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0px;
        margin-inline: auto;
      }

      .tabs-btn {
        background-color: #ffffff;
        height: 48px !important;
        padding: 0 24px;
        border: 0 !important;

        &::before {
          display: none !important;
        }

        span {
          border: 0 !important;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          color: #04c23a;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          text-transform: capitalize;

          @media only screen and (max-width: 768px) {
            font-size: 14px;
          }
        }
      }

      .ant-radio-button-wrapper-checked {
        background-color: #04c23a;
        color: #ffffff;

        span {
          color: #ffffff;
        }
      }
    }

    .management-sale-org-content-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .btn-container {
      align-self: center;

      .btn {
        border: 0;
        border-radius: 9px;
        background: #04c23a;
        color: #fff;
        font-size: 12px;

        font-style: normal;
        font-weight: 700;
        line-height: 16.5px;
        padding: 12px 18px;
        cursor: pointer;
      }
    }
  }

  @media only screen and (max-width: 1440px) {
    .container {
      width: 85%;
    }
  }

  @media only screen and (max-width: 1080px) {
    .container {
      width: 70%;
    }
  }

  @media only screen and (max-width: 768px) {
    .container {
      width: 95%;
    }
  }
}
