.more-info-container-space {
  margin-top: 0px !important;
}

.more-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin-top: 20px;
  
  .pay-method {
    display: flex;
    align-items: center;
    gap: 5px;
    .pay_m {
      color: var(--dark-blue, #1b1c57);
      font-family: Cairo;
      font-size: 9px;
      font-style: normal;
      font-weight: 700;
    }
    .pay_tag {
      display: flex;
      padding: 1.5px 6px;
      justify-content: center;
      align-items: center;
      gap: 3px;
      border-radius: 9px;
      background: var(--light-green, #eaf4ee);
      color: var(--dark-blue, #1b1c57);
      font-size: 7.5px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
  }
  .btn {
    padding: 3px 6px;
    border: 0;
    border-radius: 6px;
    background: rgba(196, 196, 196, 0.2);
    color: #04c23a;
    font-size: 10.5px;

    cursor: pointer;

    font-style: normal;
    font-weight: 700;
    line-height: 16.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    span {
      color: #626687;
      font-size: 7.5px;

      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .available_days {
    cursor: pointer;
  }
  .contact-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;

    .ref-id {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      color: #04c23a;
      font-size: 10.5px;
      font-style: normal;
      font-weight: 700;
      line-height: 16.5px;
      cursor: pointer;
    }
    
    .contact-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 28px;
      height: 28px;
      border: 0;
      border-radius: 9px;
      background: #eaf4ee;
    }
    .logo_property {
      width: 45px;
      height: 28px;
    }
  }
}
