.flip-arrow {
  transform: rotate(180deg);
}

.arrow-wrapper {
  display: flex;
  flex-direction: column;
  column-gap: 0;
  fill: black !important;
  
}

.input-wrap {
  display: flex;
  align-items: center;
  text-align: center;
  
}

.day-since-wrap {
  display: flex;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #ececec;
  font-size: small;
  width: 33;
  height: 33px;
  border-radius: 5px !important;
  padding: 5px;
}
