.forgot_pwd_page {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 70px 16px;
  background: #f5f5f5;
  height: 100vh;
  width: 100%;
  .content {
    max-width: 410px;
    width: 100%;
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 28px;
    }
    form {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;
      margin-top: 32px;

      .inputs_f_pwd {
        width: 100%;
        margin-bottom: 20px;
        .s_input {
          width: 100%;
          height: 46px;
        }
      }
    }
    .text {
      font-weight: 700;
      font-size: 15px;
      line-height: 30px;
      color: #626687;
    }
  }
}
