.profile-input {
  max-height: 42px;
  overflow: hidden;
  border-radius: 9px;
  border: 0.75px solid #1b1c57;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;

  .ant-input {
    border: 0;
    border-radius: 9px;
    background: #fff;
    height: 42px;
    outline: none;
    box-shadow: none;
  }

  .ant-input-affix-wrapper {
    border: 0;
    background: #fff;
    border-radius: 9px;
    height: 42px;
    justify-content: center;
    align-items: center;
    outline: none;
    box-shadow: none;
  }

  .ant-select {
    .ant-select-selector {
      background: transparent !important;
      border: 0 !important;
      outline: none !important;
      box-shadow: none !important;

      .ant-select-selection-item {
        .select-flag-container {
          span {
            color: #04c23a;
          }
        }
      }
    }
  }
}
#err_phone {
  border: 1px solid red !important;
}
.profile-input-disabled {
  max-height: 42px;
  overflow: hidden;
  border-radius: 9px;
  background: #eaf4ee;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;

  .ant-input {
    border: 0;
    border-radius: 9px;
    background: #eaf4ee;
    height: 42px;
    outline: none;
    box-shadow: none;
  }

  .ant-input-affix-wrapper {
    border: 0;
    background: #eaf4ee;
    border-radius: 9px;
    height: 42px;
    justify-content: center;
    align-items: center;
    outline: none;
    box-shadow: none;
  }

  .ant-select {
    .ant-select-selector {
      background: transparent !important;
      border: 0 !important;
      outline: none !important;
      box-shadow: none !important;

      .ant-select-selection-item {
        .select-flag-container {
          span {
            color: #04c23a;
          }
        }
      }
    }
  }
}
