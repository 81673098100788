.management-sale-org-card-container {
  border-radius: 12px;
  background: #fff;
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  .org-image {
    max-height: 245px;
    width: 100%;
    border-radius: 12px;
  }

  .row2 {
    width: 100%;

    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;

      .title {
        color: #1b1c57;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        text-transform: capitalize;
        text-align: start;
      }

      .subtitle {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        color: #1d4ed8;
        font-size: 10.5px;

        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }

    .content-container {
      p {
        color: #1b1c57;
        text-align: right;
        font-size: 12px;

        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        text-align: start;

        span {
          color: #1d4ed8;
          text-align: right;
          font-size: 12px;

          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          margin: 0 8px;
        }
      }

      table {
        tr {
          th {
            &:last-child {
              padding-left: 24px;
            }
          }
        }
      }
      .pay-method {
        display: flex;
        align-items: center;
        gap: 5px;
        .pay_m {
          color: var(--dark-blue, #1b1c57);
          font-family: Cairo;
          font-size: 9px;
          font-style: normal;
          font-weight: 700;
        }
        .pay_tag {
          display: flex;
          padding: 1.5px 6px;
          justify-content: center;
          align-items: center;
          gap: 3px;
          border-radius: 9px;
          background: var(--light-green, #eaf4ee);
          color: var(--dark-blue, #1b1c57);
          font-size: 7.5px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: capitalize;
        }
      }
      .contect-btns {
        margin-top: 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        @media only screen and (max-width: 420px) {
          flex-direction: column;
        }

        .btn {
          border: 0;
          display: flex;
          padding: 6px;
          justify-content: center;
          align-items: center;
          gap: 7.5px;
          border-radius: 9px;
          background: rgba(196, 196, 196, 0.5);
          cursor: pointer;
        }
      }
    }
  }

  .row3 {
    display: flex;
    flex-direction: column;
    gap: 18px;

    .share-property-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 18px;

      .label {
        color: #1b1c57;
        text-align: center;
        font-size: 12px;

        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .ant-switch {
        min-width: 37.5px;
      }

      .ant-switch-checked {
        background-color: #04c23a;
      }
    }

    .btns {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 18px;

      @media only screen and (max-width: 420px) {
        display: grid;
      }

      .btn {
        width: 100%;
        border: 0;
        border-radius: 9px;
        background: #04c23a;
        color: #fff;
        font-size: 12px;

        font-style: normal;
        font-weight: 700;
        line-height: 16.5px;
        padding: 12px 18px;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }

  @media only screen and (max-width: 1080px) {
    // display: grid;
    // justify-content: center;
    display: flex;
    flex-direction: column;

    .row3 {
      .btns {
        flex-direction: row;
      }
    }
  }
}
