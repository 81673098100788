.phone_input_component {
  // text-align: left;
  // margin-bottom: 18px;
  margin-bottom: -28px;

  input {
    margin-top: 3px;
  }
  p {
    color: red;
    font-size: 11px;
    margin-top: 0px;
    height: 30px !important;
  }
}
.err_phone {
  border: 1px solid red !important;
}

.phone-input-container {
  direction: ltr;

  display: flex !important;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  height: 46px;
  overflow: hidden;
  border: 0.75px solid rgba(196, 196, 196, 0.5);
  border-radius: 9px;
  background: white;
  padding: 0 10px;

  .inputphone {
    border: none !important;
    height: 100%;
    width: 100%;
    margin: 0;
    outline: none;
    padding: 0 6px 0 0;

    &::placeholder {
      font-family: 'Cairo';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      color: #626687;
    }
  }
  .e_n {
    direction: ltr;
  }
  .a_r {
    direction: rtl;
  }
  .ant-select-selector {
    border: none !important;
    overflow: hidden !important;
    border-radius: 0 !important;
    height: 70px;

    color: var(--basic, #04c23a);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  // .ant-select.ant-select-single.ant-select-show-arrow {
  //   margin-top: 3px;
  // }
  .ant-select-focused {
    border: none !important;
    overflow: hidden !important;
    border-radius: 0 !important;
  }
}

.select-flag-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;

  .country-flag {
    width: 18px;
    height: 18px;
    border-radius: 100%;
  }
  span::before {
    color: red;
  }
}
