.calendar-container {
  position: relative;
  width: 130px;

  .range_absolute_btn {
    position: absolute;
    // z-index: 9;
    // width: 200px;
    margin-top: 8px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #06c23a;
    color: #06c23a;
    span {
      font-size: 9px;
    }
    &:hover {
      color: #06c23a !important;
      background-color: #fff !important;
      border: 1px solid #06c23a !important;
      transition: none !important;
    }
  }
}
.range_cal {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: -20px;
  .selected_days_length {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    font-size: 11px;
    z-index: 99;
    width: 100%;

    span {
      background-color: #ededed;
      padding: 0 3px;
      border-radius: 5px;
      margin: 3px;
      font-size: 11px;
      height: 21px;
      width: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.ant-picker-range-wrapper.ant-picker-date-range-wrapper {
  width: 100%;

  .ant-picker-panel-container {
    width: 580px;
    .ant-picker-header-super-next-btn,
    .ant-picker-header-super-prev-btn {
      display: none;
    }
    .ant-picker-content {
      tr {
        display: flex;
        gap: 15px;

        td {
          width: 60px;
          height: 32px;
          // background: green;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .ant-picker-cell-selected {
        .ant-picker-cell-inner {
          background-color: #06c23a !important;
          border: none !important;
          &::before {
            border: none;
          }
        }
      }
      .ant-picker-cell-in-range {
        .ant-picker-cell-inner {
          background-color: #06c23a !important;
          border: none !important;
          color: #fff;
          &::before {
            border: none;
          }
        }
      }
      .ant-picker-cell-range-end {
        .ant-picker-cell-inner {
          background-color: #06c23a !important;
          border: none !important;
          color: #fff;
          &::before {
            border: none;
          }
        }
      }
    }
    // .ant-picker-cell {
    //   .day {
    //     background-color: red;
    //     // height: 50px;
    //     // width: 50px;
    //   }
    // }
  }
}

.footer_picker {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px 10px;
  .actions {
    display: flex;
    gap: 10px;
    button {
      background-color: #06c23a;
      border: none;
      box-shadow: none;
      &:hover {
        background-color: #06c23a !important;
      }
    }
    .reset_picker {
      background: rgb(191, 191, 191);
    }
  }
}

// respinsive cal
.range_picker_en {
  @media only screen and (max-width: 800px) {
    .ant-picker-range-wrapper.ant-picker-date-range-wrapper {
      width: 300px;
    }

    .ant-picker-panels {
      // Your styles for the parent container
      width: 300px;

      > *:not(:nth-child(1)) {
        // Your styles for all children except the second one
        background-color: red !important;
        display: none;
      }
    }
  }
}

.range_picker_ar {
  @media only screen and (max-width: 800px) {
    .ant-picker-range-wrapper.ant-picker-date-range-wrapper {
      width: 300px;
    }

    .ant-picker-panels {
      // Your styles for the parent container
      width: 300px;

      > *:not(:nth-child(2)) {
        // Your styles for all children except the second one
        background-color: red !important;
        display: none;
      }
    }
  }
}

.ant-picker-header-prev-btn,
.ant-picker-header-next-btn {
  display: block;
  visibility: visible !important;
}
