.ldap {
  width: 100%;
  margin-top: 16px;
  .divider_text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
    margin-bottom: 16px;

    .divider {
      // width: 100%;
      height: 1px;
      background-color: #1b1c57;
      width: 100%;
    }
    .text {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      text-align: right;
      text-transform: capitalize;
      white-space: nowrap;

      color: #1b1c57;
    }
  }
  .items {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    img {
      cursor: pointer;
    }
  }
}
