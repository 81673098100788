@use "../../components/Ldap/ldap";

.register_page {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 70px 16px;
  background: #f5f5f5;
  .content {
    max-width: 780px;
    width: 100%;

    form {
      display: flex;
      // align-items: center;
      flex-direction: column;
      width: 100%;
      margin-top: 32px;

      .login_action {
        margin-bottom: 28px;

        .remember {
          flex-direction: column !important;
          display: flex;
        }
      }
    }
    .grid_items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 28px;
      width: 100%;

      @media only screen and (max-width: 700px) {
        grid-template-columns: 1fr;
        grid-gap: 0px;
      }

      .left_fields {
        .inputs_login {
          margin-bottom: 16px;
        }
      }
    }
    .bottom {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .actions {
      width: 100%;
      max-width: 410px;

      width: 100%;
    }
    .account {
      font-weight: 600;
      font-size: 16px;
      line-height: 30px;
      text-transform: capitalize;
      color: #000000;
      text-align: center;
      margin-top: 15px;
      span {
        color: #1d4ed8;
        cursor: pointer;
      }
    }
  }
}
