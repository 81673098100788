.specifications-filters {
  display: flex;
  flex-direction: column;
  gap: 12px;

  hr {
    border: 0.5px solid rgba(196, 196, 196, 0.5);
  }

  .label {
    color: #1b1c57;
    font-size: 10.5px;

    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    white-space: nowrap;
  }

  .price-determined-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btns-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;

    .btn {
      padding: 3px 6px;
      border-radius: 9px;
      border: 0.75px solid #04c23a;
      background: #fff;
      color: #04c23a;

      font-size: 8.5px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      cursor: pointer;
      white-space: nowrap;
    }

    .btn-active {
      background: #04c23a;
      color: #fff;
    }

    span {
      color: #000;

      font-size: 9px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
    }
  }

  .smaller-content-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}
