.broker-profile {
  padding-bottom: 200px; //  TODO: remove this

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;

  .broker-profile-content {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    .header-info {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;

      .logo {
        width: 319px;
        height: 245.25px;

        @media only screen and (max-width: 400px) {
          width: 60%;
        }

        @media only screen and (max-width: 1024px) {
          width: 137.876px;
          height: 106px;
        }
      }

      .info-content {
        margin-top: 30px;

        .title {
          color: #1b1c57;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 133.333% */
          text-transform: capitalize;
        }

        .subtitle {
          margin-top: 6px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 6px;
          color: rgba(29, 78, 216, 0.8);
          font-size: 10.5px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 171.429% */
        }

        .btns {
          margin-top: 12px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 12px;

          a {
            padding: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
            border-radius: 9px;
            background: #eaf4ee;
            border: 0;
            white-space: nowrap;
          }

          // @media only screen and (max-width: 720px) {
          //   display: grid;
          //   grid-template-columns: 1fr 1fr;
          // }

          // @media only screen and (max-width: 500px) {
          //   display: grid;
          //   grid-template-columns: 1fr;
          // }
        }

        .sm {
          margin-top: 12px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 32px;

          .block {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 9px;
            color: #1b1c57;
            font-size: 10.5px;
            font-style: normal;
            font-weight: 700;
            line-height: 16.5px; /* 157.143% */
          }

          .sub {
            color: #1b1c57;
            font-size: 10.5px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; /* 171.429% */
          }
        }

        .visit-btn {
          margin-top: 12px;
          display: flex;
          padding: 12px 18px;
          justify-content: center;
          align-items: center;
          gap: 6px;
          border-radius: 9px;
          background: #1b1c57;
          border: 0;
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16.5px; /* 137.5% */
          cursor: pointer;
        }

        @media only screen and (max-width: 1024px) {
          .mobile-no {
            display: none;
          }
        }
      }

      @media only screen and (max-width: 1024px) {
        // flex-direction: column;

        .info-content {
          margin-top: 0;

          .btns {
            span {
              display: none;
            }
          }
        }

        img {
          align-self: center;
        }
      }
    }

    .tabs {
      align-self: center;
      margin-bottom: 24px;

      .tabs-btn {
        background-color: #ffffff;
        height: 48px !important;
        padding: 0 24px;
        border: 0 !important;

        &::before {
          display: none !important;
        }

        span {
          border: 0 !important;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          color: #04c23a;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          text-transform: capitalize;
        }

        @media only screen and (max-width: 520px) {
          padding: 0 12px;

          span {
            font-size: 12px;
          }
        }
      }

      .ant-radio-button-wrapper-checked {
        background-color: #04c23a;
        color: #ffffff;

        span {
          color: #ffffff;
        }
      }
    }

    .swipers-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 24px;
      width: 85%;
      @media only screen and (max-width: 520px) {
        width: unset;
      }

      .title {
        color: #1b1c57;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }

      .swipers-container-btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 180px;

        .swipers-container-btn {
          padding: 12px 18px;
          border-radius: 9px;
          background: #04c23a;
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16.5px; /* 137.5% */
          border: 0;
          cursor: pointer;
        }
      }

      @media only screen and (max-width: 520px) {
        width: 100%;
      }
    }
  }

  .cards-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 105%;
    align-self: center;

    @media only screen and (max-width: 520px) {
      width: 100%;
    }

    .btn {
      cursor: pointer;
      @media only screen and (max-width: 520px) {
        display: none;
      }
    }

    // .list-sale-card {
    //   display: grid;
    //   justify-content: center;
    //   padding: 12px;
    //   gap: 0;
    //   max-width: 404px;
    //   @media only screen and (max-width: 520px) {
    //     max-width: 349px;
    //   }

    //   .card-body {
    //     padding: 8px;
    //   }

    //   .picture-container {
    //     justify-self: center;
    //     width: 100%;

    //     .picture {
    //       width: 100%;
    //     }
    //   }
    // }
    .swiper-slide {
      max-width: 404px;
      // @media only screen and (max-width: 520px) {
      //   max-width: 349px;
      // }
    }
  }
}

//css style related to the card in the n=broker page
.broker {
  width: 390px;
  display: grid;
  height: 100%;
  @media only screen and (max-width: 820px) {
    padding-top: 12px;
  }
  .picture-container {
    justify-self: center;
    width: 100% !important;
    min-width: 100%;
    width: 365px !important;
    height: 250px;

    .picture {
      width: 100% !important;
      min-width: 100%;
      width: 365px !important;
    }
  }
  .specifications {
    .tags {
      max-width: 280px !important;
    }
  }
  .description {
    max-width: 320px;
  }
  .tabs-container {
    .ant-tabs-tab {
      padding: 5px 0 !important;
      margin: 0 10px !important;

      @media only screen and (max-width: 1220px) {
        margin: 0 10px !important;
      }
      @media only screen and (max-width: 920px) {
        margin: 0 6px !important;
      }

      &:first-child {
        margin: 0;
      }
    }
  }
  .localisation-container {
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 150px;
    }
  }
}
