.checkbox-input {
  display: flex;
  align-items: center;
  gap: 8px;

  input[type='checkbox'] {
    accent-color: #626687;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  label {
    color: #1b1c57;
    font-size: 10.5px;

    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
