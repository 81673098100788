.countdown-container {
  display: flex;
  align-items: center;
  justify-content: space-between;


  background-color: #e1e1e1;

  border-radius: 5px;
  width: 100%;
  padding: 0.2rem 0.3rem;
  gap: 0.375rem;
}

.date-wrapper {
  display: flex;

  height: 15px;
  gap: 2px;

}

.date-number {
  background: #FFF;
  font-family: Cairo;
  font-size: 13px;
  font-weight: 400;
  line-height: 14px;
  text-align: right;
  border-radius: 0.125rem;

  padding: 0rem 0.25rem;
  gap: 0px;
  border-radius: 2px 2px 2px 2px;

  color: #494949;
  text-align: right;
  font-style: normal;
  font-weight: 400;
}

.date-text {
  font-family: Cairo;
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  text-align: right;
  padding: 1px;
}

.webpage {
  display: flex;
  align-items: center;
  gap: 13px;
}

.green-bg {
  background-color: #04c23a !important;
  color: white;
}

.green-bg:hover {
  color: white !important;
}

.tooltip-color {
  color: black !important;
  font-size: smaller;
}

.icon-wrapper {
  width: auto;
  margin-top: 5px;
  border-radius: 0.5rem;
}

.action-wrapper {
  position: relative;
  bottom: -63px;
}

@media only screen and (max-width: 820px) {
  .action-wrapper {
    position: relative;
    bottom: 0px;
  }
}